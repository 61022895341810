import React, { useState, useEffect } from "react"
import cn from "classnames"
import { graphql, PageProps } from "gatsby"
import { InView } from "react-intersection-observer"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import ButtonLink from "@components/UI/Buttons/ButtonLink"
import Circle from "@components/Circle"
import { NY_HUB_LINK } from "@helpers/constants"
import { sanitize } from "@helpers/helpers"
import { SignupFinishDataType } from "@typings/requests/partners/sign-up-finish"
import "./sign-up-finish.scss"

const SignupFinishPage: React.FC<PageProps<SignupFinishDataType>> = ({
  location,
  data,
}) => {
  const [companyLink, setsCompanyLink] = useState<string>(NY_HUB_LINK)
  const [isInView, setIsInView] = useState(false)

  const {
    allStrapiSignUpPage: {
      nodes: [signUpFinishData],
    },
  } = data

  const { locale, signUpFinish } = signUpFinishData
  const { title, subtitle, buttonLabel } = signUpFinish

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get("companyLink")) {
      setsCompanyLink(params.get("companyLink") || "")
    }
  }, [location.search])

  return (
    <>
      <SEO lang={locale}>
        <meta name="robots" content="noindex" />
      </SEO>
      <LayoutContainer
        className="signup-finish-page"
        location={location}
        lang="es"
        isPartnerSubdomain
      >
        <InView
          className="container signup-finish-page__container"
          as="section"
          triggerOnce
          threshold={[0.3]}
          onChange={setIsInView}
        >
          <div className="signup-finish-page__info">
            <h1 className="text text--h1 signup-finish-page__title">{title}</h1>
            <p
              className="text signup-finish-page__subtitle text--sub2"
              dangerouslySetInnerHTML={{
                __html: sanitize(subtitle),
              }}
            />
            <ButtonLink
              href={companyLink}
              label={buttonLabel}
              isLocal={false}
              className="signup-finish-page__button"
            />
          </div>

          <div
            className={cn(
              "signup-finish-page-circle signup-finish-page-circle--small-white",
              {
                "h-in-view": isInView,
              }
            )}
          >
            <Circle color="#ffffff" isSolid={true} />
          </div>
          <div
            className={cn(
              "signup-finish-page-circle signup-finish-page-circle--middle-white",
              {
                "h-in-view": isInView,
              }
            )}
          >
            <Circle color="#ffffff" />
          </div>
          <div
            className={cn(
              "signup-finish-page-circle signup-finish-page-circle--big-white",
              {
                "h-in-view": isInView,
              }
            )}
          >
            <Circle color="#ffffff" />
          </div>
          <div
            className={cn(
              "signup-finish-page-circle signup-finish-page-circle--small-blue",
              {
                "h-in-view": isInView,
              }
            )}
          >
            <Circle color="#431B93" />
          </div>
          <div
            className={cn(
              "signup-finish-page-circle signup-finish-page-circle--middle-blue",
              {
                "h-in-view": isInView,
              }
            )}
          >
            <Circle color="#431B93" />
          </div>
          <div
            className={cn(
              "signup-finish-page-circle signup-finish-page-circle--big-blue",
              {
                "h-in-view": isInView,
              }
            )}
          >
            <Circle color="#431B93" />
          </div>
          <div
            className={cn(
              "signup-finish-page-circle signup-finish-page-circle--small-green",
              {
                "h-in-view": isInView,
              }
            )}
          >
            <Circle color="#009B3A" isSolid={true} />
          </div>
          <div
            className={cn(
              "signup-finish-page-circle signup-finish-page-circle--big-green",
              {
                "h-in-view": isInView,
              }
            )}
          >
            <Circle color="#009B3A" isSolid={true} />
          </div>
        </InView>
      </LayoutContainer>
    </>
  )
}

export const query = graphql`
  {
    allStrapiSignUpPage(filter: { locale: { eq: "es" } }) {
      nodes {
        locale
        signUpFinish {
          buttonLabel: button
          subtitle
          title
        }
      }
    }
  }
`

export default SignupFinishPage
